import { PostAnswer, PostUser } from '../models'
import { sanitizeSchema } from '@ask-cf/utils/json-ld-helpers'

type GoogleQuestionAnswerJsonProps = {
  url: string
  question: {
    question: string
    context?: string | null
    totalAnswers?: number
    createTime: string
    answers?: PostAnswer[] | null
    user: PostUser
  }
}

export function GoogleQuestionAnswerJson(props: GoogleQuestionAnswerJsonProps) {
  const { question, url } = props
  const { answers } = question

  const suggestedAnswer = answers?.find(answer => answer?.isGenieAnswer) || answers?.[0] || null

  const getAuthorName = (user: PostUser) => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    }
    return 'User'
  }

  const jsonLdSchema = {
    '@context': 'https://schema.org',
    '@type': 'QAPage',
    mainEntity: {
      '@type': 'Question',
      name: question.question,
      text: question?.question,
      answerCount: question?.totalAnswers || 0,
      datePublished: new Date(question.createTime).toISOString(),
      author: {
        '@type': 'Person',
        name: getAuthorName(question.user),
        url: question.user.profileUrls?.crafter || 'https://ask.creativefabrica.com/',
      },
      description: question.context || '',
      suggestedAnswer: {
        '@type': 'Answer',
        text: suggestedAnswer?.answer || '',
        datePublished: suggestedAnswer?.createTime
          ? new Date(suggestedAnswer?.createTime).toISOString()
          : new Date().toISOString(),
        author: {
          '@type': 'Person',
          name: getAuthorName(suggestedAnswer?.user || question.user),
          url: suggestedAnswer?.user?.profileUrls?.crafter || 'https://ask.creativefabrica.com/',
        },
        upvoteCount: 0,
        url: url || 'https://ask.creativefabrica.com/',
      },
    },
  }

  // Sanitize the JSON-LD schema by removing undefined or null properties
  sanitizeSchema(jsonLdSchema)

  return (
    <script
      key="google-question-answers-structured-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdSchema) }}
    />
  )
}
