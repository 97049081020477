import * as React from 'react'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { Button } from 'primitives/button'
import { useIsLoggedInQuery } from 'analytics/hooks/use-is-logged-in-query'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'

import { ChatIcon } from '@ask-cf/assets/icons'
import { PostAnswer } from '../feed/models'

export function CommentButton({
  showExpanded,
  detailsHref,
  answer,
}: {
  showExpanded: boolean
  detailsHref: string
  answer: PostAnswer
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  const { data: isLoggedIn } = useIsLoggedInQuery()
  const { openModal } = useUserAuthModal()

  const handleCommentClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    if (!isLoggedIn) {
      openModal('login')
      return
    } else {
      const urlWithComment = `${detailsHref}${detailsHref.includes('?') ? '&' : '?'}comment=true`
      window.open(urlWithComment, '_blank')
    }
  }

  return showExpanded ? (
    isLoggedIn ? (
      <div className="flex items-center gap-1 p-0">
        <ChatIcon />
        <span className="hidden xl:inline-block">{t('comment_label')}</span>
      </div>
    ) : (
      <Button
        variant="invisible"
        className="hover:text-text-hover active:text-text-tertiary flex items-center gap-1 p-0"
        onClick={() => openModal('login')}
      >
        <ChatIcon />
        <span className="hidden xl:inline-block">{t('comment_label')}</span>
      </Button>
    )
  ) : (
    <Link
      href={detailsHref}
      target="_blank"
      className="hover:text-text-hover flex items-center gap-1 p-0"
      aria-label={t('comment_label')}
      onClick={handleCommentClick}
    >
      <ChatIcon />
      {answer?.commentCount && answer?.commentCount > 0 ? (
        <span className="hidden xl:inline-block">
          {answer.commentCount} {t('add_comment_to_field')}
        </span>
      ) : (
        <span className="hidden xl:inline-block">{t('comment_label')}</span>
      )}
    </Link>
  )
}
