import * as React from 'react'
import { useIsLoggedInQuery } from 'auth/hooks'

import { FeedPost } from './components/feed-post'
import { FeedSortButtons } from './components/feed-sort-buttons'
import { Post } from './models'
import { ASK_CF_FF_FEED_SORT, IS_PROD_ENV } from '@ask-cf/config'
import { PostSkeleton } from './components/post-skeleton'
import { GoogleAd } from '../google-ads'

const AD_PLACEMENT_INTERVAL = 3
const PRELOADED_POSTS_COUNT = 2

const adSlots = [
  '1090751317',
  '2543623733',
  '9279163295',
  '1110799160',
  '6388806886',
  '9746067158',
  '3176789376',
  '2392091241',
  '5419809332',
  '9709938948',
  '1817376171',
]

export function Feed({
  posts,
  hideSortButtons = false,
  loading,
  nextPage,
  hasMore,
  showOwnAnswerExpanded,
  relatedQuestionsID,
}: {
  posts: Post[]
  hideSortButtons?: boolean
  loading?: boolean
  nextPage?: () => void
  hasMore?: boolean
  showOwnAnswerExpanded?: boolean
  relatedQuestionsID?: string
}) {
  const lastPostElementRef = React.useRef<HTMLDivElement>(null)
  const observerRef = React.useRef<IntersectionObserver | null>(null)

  const { data: isLoggedIn } = useIsLoggedInQuery()

  let adIndex = 0

  // Start observing the last post to detect when it's in the viewport
  React.useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect()
    }

    observerRef.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && nextPage && !loading && hasMore) {
          nextPage()
        }
      },
      {
        root: null,
        rootMargin: '500px',
        threshold: 0,
      },
    )

    if (lastPostElementRef.current) {
      observerRef.current.observe(lastPostElementRef.current)
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [posts, nextPage, loading, hasMore])

  return (
    <div className="w-full flex-grow">
      {!hideSortButtons && isLoggedIn && ASK_CF_FF_FEED_SORT ? (
        <div className="hidden xl:mb-6 xl:block">
          <FeedSortButtons />
        </div>
      ) : null}
      <div className="flex w-full flex-col gap-4">
        {posts.map((post, index) => {
          let adSlot = null

          // Show an ad after every AD_PLACEMENT_INTERVAL posts, and select the next adSlot sequentially
          if ((index + 1) % AD_PLACEMENT_INTERVAL === 0 && adIndex < adSlots.length) {
            adSlot = adSlots[adIndex]
            adIndex++
          }

          return relatedQuestionsID !== post?.id ? (
            <div key={index} className="feed-post">
              {posts.length === index + 1 ? (
                <FeedPost
                  ref={lastPostElementRef}
                  post={post}
                  showOwnAnswerExpanded={showOwnAnswerExpanded}
                  overrideIsInView={index < PRELOADED_POSTS_COUNT}
                />
              ) : (
                <FeedPost
                  post={post}
                  showOwnAnswerExpanded={showOwnAnswerExpanded}
                  overrideIsInView={index < PRELOADED_POSTS_COUNT}
                />
              )}
              {adSlot && IS_PROD_ENV ? <FeedAd key={`ad-${index}`} adSlot={adSlot} /> : null}
            </div>
          ) : null
        })}
        {loading ? <FeedPostSkeletons /> : null}
      </div>
    </div>
  )
}

function FeedAd({ adSlot }: { adSlot: string }) {
  return (
    <>
      <GoogleAd
        adClient="ca-pub-8093072971929641"
        adSlot={adSlot}
        style={{ display: 'inline-block', width: '100%', height: '90px', marginTop: '16px' }}
        adFormat="fluid"
        layoutKey="-ft+t+66-3m+j"
      />
    </>
  )
}

export function FeedPostSkeletons() {
  return (
    <>
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
    </>
  )
}
