'use client'
import * as React from 'react'
import * as Popover from 'primitives/popover'
import Image from 'next/image'
import { motion } from 'framer-motion'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import { IconButton } from 'primitives/buttons/icon-button'
import { tw } from 'utils/classnames'
import { useAskCfPostCommentMutation } from 'codegen/generated/ask-cf'

import { ASK_CF_FF_COMMENT_PHOTO } from '@ask-cf/config'
import { EmojiPlusIcon, PhotoPlusIcon, SendIcon, DeleteIcon } from '@ask-cf/assets/icons'

export function PostCommentInput({
  answerId,
  commentParentId,
  onCommentPosted,
  initialText = '',
  onTextChange,
  isFocused,
  onFocus,
  onHideTextarea,
}: {
  answerId: string
  commentParentId?: string
  onCommentPosted: () => void
  initialText?: string
  onTextChange: (text: string) => void
  isFocused?: boolean
  onFocus?: () => void
  onHideTextarea: () => void
}) {
  const [comment, setComment] = React.useState<string>(initialText)
  const [selectedImage, setSelectedImage] = React.useState<File | null>(null)
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  const useAskCfPostComment = useAskCfPostCommentMutation()

  React.useEffect(() => {
    setComment(initialText)
  }, [initialText])

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const newText = comment + emojiData.emoji
    setComment(newText)
    onTextChange(newText)
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImage = e.target.files[0]
      setSelectedImage(newImage)
    }
  }

  const deleteImage = () => {
    setSelectedImage(null)
  }

  const handleSendComment = async () => {
    if (!comment.trim() || !isFocused) {
      return
    }

    useAskCfPostComment.mutate(
      {
        input: {
          answerId,
          comment: comment.trim(),
          comment_parent_id: commentParentId ? commentParentId : undefined,
        },
      },
      {
        onSuccess: () => {
          setComment('')
          setSelectedImage(null)
          onTextChange('')
          onCommentPosted()
          onHideTextarea() // Hide the textarea after the comment is successfully posted
        },
      },
    )
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && isFocused) {
      e.preventDefault()
      handleSendComment()
    }
  }

  React.useEffect(() => {
    if (isFocused && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [isFocused])

  return (
    <div className="relative w-full">
      <motion.div
        initial={{ height: 48 }}
        animate={{ height: isFocused ? 'auto' : 48 }}
        className={tw(
          'bg-background-primary rounded-4 relative px-4 py-2 ',
          isFocused ? '' : 'flex items-center',
        )}
        onClick={onFocus}
      >
        <textarea
          ref={textareaRef}
          placeholder="Add comment to this answer"
          value={comment}
          onChange={e => {
            setComment(e.target.value)
            onTextChange(e.target.value)
          }}
          onKeyDown={handleKeyDown}
          className="bg-background-primary Content[Text]/Tertiary w-full flex-grow resize-none overflow-y-auto text-base text-gray-700 placeholder-gray-600 outline-none"
          rows={1}
          maxLength={5000}
        />

        <div className={tw('flex items-center justify-between', isFocused && 'mt-2')}>
          <div className="flex items-center">
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
            />
            {ASK_CF_FF_COMMENT_PHOTO ? (
              <IconButton
                variant="transparent"
                onClick={() => document.getElementById('imageUpload')?.click()}
              >
                <PhotoPlusIcon className="h-4 w-4 text-gray-600" />
              </IconButton>
            ) : null}

            <Popover.Root>
              <Popover.Trigger asChild>
                <IconButton variant="transparent">
                  <EmojiPlusIcon className="h-4 w-4 text-gray-600" />
                </IconButton>
              </Popover.Trigger>
              <Popover.Content className="z-50">
                <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  skinTonesDisabled={true}
                  allowExpandReactions={false}
                  searchDisabled={true}
                />
              </Popover.Content>
            </Popover.Root>
          </div>

          {isFocused ? (
            <div className="flex items-center">
              <IconButton
                variant="transparent"
                disabled={!comment.trim()}
                className={tw(
                  'ml-auto',
                  comment.trim() ? 'text-blue-500 hover:text-blue-300' : 'text-gray-400',
                )}
                onClick={handleSendComment}
              >
                <SendIcon className="h-5 w-5" />
              </IconButton>
            </div>
          ) : null}
        </div>
      </motion.div>

      {selectedImage ? (
        <div className="mt-4 flex flex-wrap gap-2">
          <div className="relative">
            <Image
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              width={96}
              height={96}
              className="h-24 w-24 rounded-lg object-cover"
            />
            <IconButton
              variant="transparent"
              className="absolute right-0 top-0 rounded-full bg-red-600 p-1 text-white"
              onClick={deleteImage}
            >
              <DeleteIcon className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
      ) : null}
    </div>
  )
}
